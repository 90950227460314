import * as Yup from 'yup';

import { OrderItem } from 'api/catalog/products';

import { IUpdateProductInput, ProductNode } from 'types/catalog';

export type SchemaObject<T> = {
  [key in keyof T]: Yup.AnySchema;
};

export const LoginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('It looks like that isn’t a valid email address.')
    .required('Required field.'),
  password: Yup.string().required('Required field.'),
});

export const ResetPasswordValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const AcceptInvitationValidationSchema = Yup.object().shape({
  password: Yup.string()
    .required('Password is required')
    .min(8, 'Password must be at least 8 characters'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Confirm password is required'),
});

export const ForgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('It looks like that isn’t a valid email address.')
    .required('Required field.'),
});

export const RegisterPersonValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('It looks like that isn’t a valid email address.')
    .required('Required field.'),
  companyId: Yup.string().required('Required field.'),
  firstName: Yup.string().required('Required field.'),
  lastName: Yup.string(),
  relationshipType: Yup.string().required('Required field.'),
});

export const getInventoryLocationValidationSchema = validationSchema => {
  const dynamicFieldsValidationSchema = Object.entries(validationSchema).map(
    ([item]) => [
      item,
      Yup.string()
        .required('Is Required')
        .matches(/^[0-9]*$/, 'Quantity is not valid.'),
    ]
  );

  return Yup.object().shape({
    ...Object.fromEntries(dynamicFieldsValidationSchema),
  });
};

export const AddRoleValidationSchema = Yup.object().shape({
  roleId: Yup.string().required("Role can't be blank"),
});

const getShipToValidationSchema = () => ({
  shipToName: Yup.string()
    .required("Name can't be blank")
    .nullable()
    .min(5, 'Enter at least 5 characters'),
  shipToEmail: Yup.string().nullable().email('Please enter a valid email'),
  shippingAddress: Yup.object({
    address1: Yup.string().required("Address line can't be blank").nullable(),
    country: Yup.string().required('Choose a country').nullable(),
    state: Yup.string().required('Choose a state').nullable(),
    postalCode: Yup.string().required('Choose a zip code').nullable(),
    city: Yup.string()
      .required("City can't be blank")
      .nullable()
      .min(3, 'Enter at least 3 characters'),
  }),
});

const getBillToValidationSchema = () => ({
  billToName: Yup.string()
    .nullable()
    .when(['shipToSameAsBillTo'], {
      is: false,
      then: Yup.string()
        .required("Name can't be blank")
        .nullable()
        .min(5, 'Enter at least 5 characters'),
    }),
  billToEmail: Yup.string()
    .nullable()
    .when(['shipToSameAsBillTo'], {
      is: false,
      then: Yup.string()
        .required("Name can't be blank")
        .nullable()
        .min(5, 'Enter at least 5 characters'),
    }),
  billingAddress: Yup.object()
    .nullable(true)
    .when(['shipToSameAsBillTo'], {
      is: false,
      then: Yup.object().shape({
        address1: Yup.string()
          .required("Address line can't be blank")
          .nullable(),
        country: Yup.string().required('Choose a country').nullable(),
        state: Yup.string().required('Choose a state').nullable(),
        postalCode: Yup.string().required('Choose a zip code').nullable(),
        city: Yup.string()
          .required("City can't be blank")
          .nullable()
          .min(3, 'Enter at least 3 characters'),
      }),
    }),
});

const lineItemsValidationSchema = Yup.array().of(
  Yup.object().shape<SchemaObject<OrderItem>>({
    id: Yup.string(),
    product: Yup.object().shape<SchemaObject<Partial<ProductNode>>>({
      id: Yup.string().required('Line Item not found'),
    }),
    quantity: Yup.string()
      .required('Is Required')
      .matches(/^\d+$/, 'Must be a number.'),
  })
);

export const EditOrdersValidationSchema = Yup.object().shape({
  ...getShipToValidationSchema(),
  ...getBillToValidationSchema(),
  brandId: Yup.string().required('Is Required'),
  orderNumber: Yup.string().min(3, 'Enter at least 3 characters').nullable(),
  expectedShipDate: Yup.string().nullable(),
  salesChannelId: Yup.string().required('Is Required'),
  note: Yup.string().test(
    'len',
    'This field needs to be at least 10 characters long.',
    value => {
      if (value === undefined) {
        return true;
      }
      return value.length === 0 || value.length >= 10;
    }
  ),
  orderItems: lineItemsValidationSchema,
});

export const AsnValidationSchema = Yup.object().shape({
  asnItems: lineItemsValidationSchema,
  brandId: Yup.string().required("Brand can't be blank"),
  trackingNumber: Yup.string()
    .required("Tracking number can't be blank")
    .matches(/^[a-zA-Z0-9]+$/, 'Special characters not allowed'),
  poNumber: Yup.string().required("PO Number can't be blank"),
  asnType: Yup.string().required('Choose an ASN type'),
  carrierId: Yup.string().required('Choose a carrier'),
  carrierType: Yup.string().required('Choose a carrier type'),
  warehouseId: Yup.string().required('Choose a warehouse'),
  note: Yup.string().test(
    'len',
    'This field needs to be at least 10 characters long.',
    value => {
      if (value === undefined) {
        return true;
      }
      return value.length === 0 || value.length >= 10;
    }
  ),
});

export const ShipOrderValidationSchema = Yup.object().shape({
  shippedDate: Yup.date()
    .required('Choose a date')
    .nullable()
    .typeError('Invalid Date'),
  trackingNumber: Yup.string()
    .required('Tracking number is required')
    .nullable(),
  numberOfPallets: Yup.number()
    .typeError('Number of pallets must be a number')
    .positive()
    .min(0)
    .nullable(),
  packageType: Yup.string().nullable(),
  numberOfPackages: Yup.number()
    .typeError('Number of packages must be a number')
    .positive()
    .min(0)
    .nullable(),
  weight: Yup.string().nullable(),
  shippingCarrierService: Yup.string().required('Choose a carrier').nullable(),
});

const PackagingHierarchyValidation = Yup.object().shape({
  quantity: Yup.number().nullable(true),
  gtin: Yup.string().nullable(true).matches(/^(.{14})$/, 'GTIN must have 14 characters'),
  weight: Yup.number().nullable(true).min(0, 'Weight must be greater than 0'),
  length: Yup.number().nullable(true).min(0, 'Weight must be greater than 0'),
  width: Yup.number().nullable(true).min(0, 'Weight must be greater than 0'),
  height: Yup.number().nullable(true).min(0, 'Weight must be greater than 0'),
});

export const EditCatalogItemSchema = Yup.object().shape<
  SchemaObject<IUpdateProductInput>
>({
  name: Yup.string()
    .required("Item name can't be blank")
    .min(3, 'Enter at least 3 characters')
    .nullable(),
  upc: Yup.string()
    .matches(/^\d{12,13}$/, 'UPC must be 12 or 13 characters')
    .nullable(true),
  productId: Yup.string().required(),
  weight: Yup.number()
    .nullable(true)
    .test('is-greater-than-zero', 'Weight must be greater than 0', value => {
      return value === null || value > 0;
    }),
  shippingInput: Yup.object().shape({
    weightFactor: Yup.number()
      .nullable(true)
      .min(0, 'Weight Factor must be greater than 0'),
  }),
  innerPack: Yup.boolean(),
  innerPackInput: Yup.object()
    .nullable(true)
    .when('innerPack', {
      is: true,
      then: Yup.object().shape({
        ...PackagingHierarchyValidation.fields,
        weightFactor: Yup.number()
          .nullable(true)
          .min(0, 'Weight Factor must be greater than 0'),
      }),
    }),
  masterCarton: Yup.boolean(),
  masterCartonInput: Yup.object()
    .nullable(true)
    .when('masterCarton', {
      is: true,
      then: Yup.object().shape({
        ...PackagingHierarchyValidation.fields,
        weightFactor: Yup.number()
          .nullable(true)
          .min(0, 'Weight Factor must be greater than 0'),
      }),
    }),
  pallet: Yup.boolean(),
  palletInput: Yup.object().nullable(true).when('pallet', {
    is: true,
    then: PackagingHierarchyValidation,
  }),
});

export const CreateCatalogItemSchema = Yup.object().shape<
  SchemaObject<IUpdateProductInput>
>({
  name: Yup.string()
    .required("Item name can't be blank")
    .min(3, 'Enter at least 3 characters')
    .nullable(),
  sku: Yup.string().nullable().required("SKU can't be blank"),
  upc: Yup.string()
    .matches(/^\d{12,13}$/, 'UPC must be 12 or 13 characters')
    .nullable(true),
  brandId: Yup.string().nullable().required('Select a Brand'),
  productId: Yup.string().nullable(),
  weight: Yup.number()
    .nullable()
    .test('is-greater-than-zero', 'Weight must be greater than 0', value => {
      return value === null || value > 0;
    }),
  shippingInput: Yup.object().shape({
    weightFactor: Yup.number()
      .nullable(true)
      .min(0, 'Weight Factor must be greater than 0'),
  }),
  innerPack: Yup.boolean(),
  innerPackInput: Yup.object()
    .nullable(true)
    .when('innerPack', {
      is: true,
      then: Yup.object().shape({
        ...PackagingHierarchyValidation.fields,
        weightFactor: Yup.number()
          .nullable(true)
          .min(0, 'Weight Factor must be greater than 0'),
      }),
    }),
  masterCarton: Yup.boolean(),
  masterCartonInput: Yup.object()
    .nullable(true)
    .when('masterCarton', {
      is: true,
      then: Yup.object().shape({
        ...PackagingHierarchyValidation.fields,
        weightFactor: Yup.number()
          .nullable(true)
          .min(0, 'Weight Factor must be greater than 0'),
      }),
    }),
  pallet: Yup.boolean(),
  palletInput: Yup.object().nullable(true).when('pallet', {
    is: true,
    then: PackagingHierarchyValidation,
  }),
});

export const ManuallyInventoryAdjustmentValidationSchema = Yup.object().shape({
  newQuantity: Yup.string().required('This is a required field.'),
  reason: Yup.string().required('This is a required field.'),
});
